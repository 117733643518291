
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { HttpServiceService } from 'src/app/shared/services/http-service.service';
import { SnackBarService } from '../../services/snackbar.service';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog/contact-dialog.component';

@Component({
  selector: 'app-call-dialog',
  templateUrl: './call-dialog.component.html',
  styleUrls: ['./call-dialog.component.scss']
})
export class CallDialogComponent implements OnInit {

  isApiCallInProgress:boolean = false
  selectedNumber:string = null
  selectedCliNumber:string = null
  srnNumbersArr:any[]=[]
  numberArr : string[]
  unMaskNumbersArr: string[];
  isNumberUnmask: boolean;
  titleStringOfUnmaskNumber: string;
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private url:ConstantsService,
    private http:HttpServiceService,
    private snackBar:SnackBarService
  ) { }

  ngOnInit() {
    // this.getSrnNumberApiCall(this.data.category)
    console.log(this.data);
    this.numberArr = this.data.mobile.map(item=> item.data)
    
  }

  openShowNumberModal(){ 
    console.log(this.data.data.leadId);
    
    const dialogRefForShow=this.dialog.open(ContactDialogComponent,{
      width:'40vw',
      panelClass:'show-number-modal',
      data:{
        leadId:this.data.data.leadId
      }
    })
    dialogRefForShow.afterClosed().subscribe((unMaskContact:string[])=>{
      if (unMaskContact && unMaskContact.length) {
        this.unMaskNumbersArr = [...unMaskContact]
        this.numberArr = [...unMaskContact]
        this.isNumberUnmask=true
        this.titleStringOfUnmaskNumber = unMaskContact.reduce((prevVal,currVal)=>{
          if (prevVal) {
            return prevVal + ',\n'+currVal
          }else{
            return currVal
          }
        },'')
      }
    })
  }

  callOnSelected(){
    console.log(this.data);
    
    this.isApiCallInProgress = true
    const reqObj:any={
      "type": "parse",
      "endPoint": "ClickTOCall",
      "data": {
        leadId:this.data.data.leadId,
        actor_id:localStorage.getItem('userId'),
        k_number:"+91" + this.selectedCliNumber,
        LeadSource:'CXDashboard',
      }
    }
    console.log(reqObj);
    
    this.http.postData(this.url.forwarding,reqObj)
    .then((res:any)=>{
      if (res.status == 200 && res.data.result.status == 1) {
        this.snackBar.success('Call will be placed shortly...')
        this.closeModal()

      }else{
        if (res.data.result.message){
          this.snackBar.warning(res.data.result.message)
        }else{
          this.snackBar.warning('Unable To make call.')
        }
      }
    })
    .catch(err=>{
      this.snackBar.error('Unable To make call!')
    })
    .finally(()=>{
      this.isApiCallInProgress=false
    })
  }

  getSrnNumberApiCall(category:string){
    const reqObj:any = {
      category:category,
      id:localStorage.getItem('userId')
    }
    this.isApiCallInProgress = true
    this.http.postData(this.url.srNumber,reqObj)
    .then((res)=>{
      console.log(res);//TODO: 
    })
    .catch(err=>{
      this.snackBar.error('Unable to get CLI number !')
    })
    .finally(()=>{
      this.isApiCallInProgress=false
    })
  }

  closeModal(){
    this.dialogRef.close()
  }

}