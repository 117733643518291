import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private snackBar:MatSnackBar
  ) { }

  matSnackBarConfig:MatSnackBarConfig = {
    duration:3000,
    horizontalPosition:'end',
    verticalPosition:"bottom",
    politeness:'polite'
  }

  success(message:string,action:any=null){
    this.snackBar.open(message,action,{
      ...this.matSnackBarConfig,
      panelClass:'snackbar-success'
    })
  }

  error(message:string,action:any=null){
    this.snackBar.open(message,action,{
      ...this.matSnackBarConfig,
      panelClass:'snackbar-error'
    })
  }

  info(message:string,action:any=null){
    this.snackBar.open(message,action,{
      ...this.matSnackBarConfig,
      panelClass:'snackbar-info'
    })
  }

  warning(message:string,action:any=null){
    this.snackBar.open(message,action,{
      ...this.matSnackBarConfig,
      panelClass:'snackbar-warning'
    })
  }

}
