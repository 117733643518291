import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appOtpText]'
})
export class OtpTextBoxDirective {
  @HostBinding('value') textVal: string = '';
  @HostListener('keyup', ['$event']) onKeyDown(keyboardEvent: KeyboardEvent) {
    const { code } = keyboardEvent
    const target = keyboardEvent.target as
      | HTMLInputElement
      | HTMLTextAreaElement
      | null;

    const { type } = target;
    let { nextElementSibling } = target;
    let {previousElementSibling} = target;
    while (code === 'Backspace' && previousElementSibling) {
      if (previousElementSibling) {
        (previousElementSibling as HTMLInputElement | HTMLTextAreaElement).focus();
        return;
      }
    }
    if (!target || target.maxLength !== target.value.length){
      target.value = target.value[target.value.length-1]
      return
    } 
      

    keyboardEvent.preventDefault();
    while (nextElementSibling) {
      if ( nextElementSibling &&
        (nextElementSibling as HTMLInputElement | HTMLTextAreaElement).type ===
        type
      ) {
        (nextElementSibling as HTMLInputElement | HTMLTextAreaElement).focus();
        return;
      }
       nextElementSibling = nextElementSibling.nextElementSibling;
    }
    
  }

}