import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
  } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { BehaviorSubject, Observable, throwError } from 'rxjs';
  import { catchError, map, filter, finalize, switchMap, take } from 'rxjs/operators';
  // import { AuthService } from './auth.service';
  import { of } from 'rxjs';

  import { ConstantsService } from '../services/constants.service';
  import {
    HttpClient,
    HttpClientModule,
    HttpHeaders,
  } from '@angular/common/http';
  import { environment } from '../../../environments/environment';
  import { Router } from '@angular/router';
  import { ToastrService } from 'ngx-toastr';
//   import { LoginService } from 'src/app/login/login.service';
  import { HttpServiceService } from 'src/app/shared/services/http-service.service';

  @Injectable()
  export class JwtAuthInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;

    beforeLoginheader = {
      'Content-Type': 'application/json',
    };
    private refreshTokenSubject = new BehaviorSubject<any>(null);

    constructor(
      private http: HttpClient,
      private httpService:HttpServiceService,
      public router: Router,
      private toastr: ToastrService,
      private constants: ConstantsService,
    //   private loginService:LoginService
      // private authService: AuthService
    ) { }

    intercept(
      request: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      return next.handle(this.addAuthToken(request)).pipe(
        catchError((requestError: HttpErrorResponse) => {
          if (requestError && (requestError.status === 401)) {
            if (this.refreshTokenInProgress) {
              return this.refreshTokenSubject.pipe(
                filter((result) => result),
                take(1),
                switchMap(() => next.handle(this.addAuthToken(request)))
              );
            } else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);
              return this.refreshAuthToken().pipe(
                switchMap((token) => {
                  Object.entries(token).forEach(([key, value]) => {
                    if (typeof value === "object") {
                      value = JSON.stringify(value)
                    }
                    localStorage.setItem(key, value)
                  });
                  localStorage.setItem('isCXLogged99','true');
                  this.refreshTokenSubject.next(token);
                  return next.handle(this.addAuthToken(request));
                }), catchError((err) => {
                  this.toastr.error('SESSION HAS EXPIRED\nPLEASE LOGIN AGAIN');
                  localStorage.clear();
                  this.router.navigate(['/login']);
                  return throwError(err);
                }),
                finalize(() => (this.refreshTokenInProgress = false))
              );
            }
          } else {
            this.httpService.pushErrorLogToLambda(request.url,request.body,requestError)
            return throwError(() => new Error(requestError.message));
          }
        })
      ) as Observable<HttpEvent<any>>;
      ;
    }

    addAuthToken(request: HttpRequest<any>) {
      const token = localStorage.getItem('access_token')

      if (!token) {
        return request;
      }

      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    refreshAuthToken() {
        const httpOptionsAfter: { headers } = {
          headers: new HttpHeaders(
            this.beforeLoginheader
          ),
        };

        return this.http.post(
            this.constants.refreshToken,
            {
                username:
                    "cxDash_" +
                    localStorage.getItem(
                        "Mobile"
                    ),
                refresh_token:
                    localStorage.getItem(
                        "refresh_token"
                    ),
            },
            httpOptionsAfter
        )
      }
  }
