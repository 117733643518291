import { ClickToCallComponent } from './layout/components/clickToCall/clickToCall.component';
import { UtilService } from './shared/services/util.service';
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LanguageTranslationModule } from "./shared/modules/language-translation/language-translation.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./shared";
import { HttpServiceService } from "./shared/services/http-service.service";
import { ConstantsService } from "./shared/services/constants.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { ToastrModule } from "ngx-toastr";
import { Ng2OrderModule } from "ng2-order-pipe";
import { NgxPaginationModule } from "ngx-pagination";
import { CommonService } from "./shared/services/common.service";
import { PatientJourneyComponent } from "./layout/components/patientJourney/patientJourney.component";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { DatePipe } from "@angular/common";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './shared/guard/auth.inspector';
import { JwtAuthInterceptor } from './shared/interceptor/ jwt-auth.interceptor';
import { MatButtonModule, MatDialogModule, MatFormFieldControl, MatFormFieldModule, MatIconModule, MatInputModule, MatLabel, MatOptionModule, MatProgressSpinnerModule, MatSelectModule, MatSnackBarModule } from '@angular/material';
import { ContactDialogComponent } from './shared/dialogs/contact-dialog/contact-dialog/contact-dialog.component';
import { OtpTextBoxDirective } from './shared/directives/otp-text-box.directive';
import { CallDialogComponent } from './shared/dialogs/call-dialog/call-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 2000,
            positionClass: "toast-top-right",
            progressAnimation:"decreasing",
            progressBar:true
        }),
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        Ng2SearchPipeModule,
        Ng2OrderModule,
        NgxPaginationModule,
        AngularMultiSelectModule,
        MatIconModule,
        MatOptionModule,
        MatSelectModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatSnackBarModule,
        MatProgressSpinnerModule
    ],
    declarations: [AppComponent, PatientJourneyComponent, ClickToCallComponent, ContactDialogComponent, OtpTextBoxDirective, CallDialogComponent],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtAuthInterceptor, multi: true },
        AuthGuard,
        HttpServiceService,
        ConstantsService,
        CommonService,
        UtilService,
        DatePipe,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: TokenInterceptor,
        //     multi: true
        // }
    ],
    bootstrap: [AppComponent],
    entryComponents: [PatientJourneyComponent, ClickToCallComponent,ContactDialogComponent,CallDialogComponent],
})
export class AppModule {}
