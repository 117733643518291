//******************************************
//    Change this for ENVIRONMENT !!!!     *
var production:any = true;   //  *
//******************************************
// ENVIRONMENT |  VALUE
//------------------------
//  Production |  true
//------------------------
//  Staging    |  false
//------------------------
//  Localhost  |   0
//------------------------

export const environment = {
  production: production==true?true:false,
  version: "0.0.0",
  webSocketUrl: production ? 'wss://4y19z1rdrk.execute-api.ap-south-1.amazonaws.com/production':"wss://4y19z1rdrk.execute-api.ap-south-1.amazonaws.com/development",
  routeUrl: production ? 'https://pristyntech.com/java/': "http://pristyn-java-staging-1025160438.ap-south-1.elb.amazonaws.com/",
  docServiceUrl : production ? 'https://pristyntech.com/doc' : 'https://node-staging.pristoncare.com/bch/bd-dash',
  docServiceUrlv1 : production ? 'https://pristyntech.com/java' : 'http://pristyn-java-staging-1025160438.ap-south-1.elb.amazonaws.com',
  otpUrl : production ? "https://pristyntech.com/doc/auth/submit-otp" : "https://node-staging.pristoncare.com/bch/bd-dash/auth/submit-otp",
  googleClientId : production ? "173529304992-nam32gn8c5f3kofuer0ns5o4dpg0i2db.apps.googleusercontent.com" : "173529304992-mdptju7mpgevb8p547u8njn100pa4bom.apps.googleusercontent.com",
};
