import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsService } from '../../../shared/services/constants.service';
import { HttpServiceService } from '../../../shared/services/http-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-download',
    templateUrl: './patientJourney.component.html',
    styleUrls: ['./patientJourney.component.css']
})
export class PatientJourneyComponent implements OnInit {
    @Input() data;
    loading: boolean = false;
    p: number = 1;
    url:any = ""
    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private http: HttpServiceService,
        private constants: ConstantsService,
        private toastr: ToastrService
    ) {
    }

    ngOnInit() {
        // this.url = 
    }
}
