import { ToastrService } from 'ngx-toastr';
import { ClickToCallComponent } from './../../layout/components/clickToCall/clickToCall.component';
import { Injectable } from '@angular/core';
import { ConstantsService } from '../../shared/services/constants.service';
import { HttpServiceService } from '../../shared/services/http-service.service';
import { PatientJourneyComponent } from '../../layout/components/patientJourney/patientJourney.component';
import {
    DomSanitizer,
    SafeResourceUrl,
    SafeUrl,
} from '@angular/platform-browser';
import {
    NgbActiveModal,
    NgbModal,
    NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { CallDialogComponent } from '../dialogs/call-dialog/call-dialog.component';
import { Subject } from "rxjs";
import { WebSocketService } from './web-socket.service';
import { environment } from "../../../environments/environment";

@Injectable()
export class CommonService {
    issueCategory = [];
    doctorArray = [];
    hospitalArray = [];
    userHandleCategory: any;
    isAdmin: any;
    questionsArray: any;
    cxArray = [];
    pjURL: any = [];
    doctorArrayNew = [];
    doctorArrayIdNew = [];
    hospitalArrayNew = [];
    getAllData:boolean = false;
    dropdownSettingsNew = {
        text: 'Select',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        singleSelection: true,
        enableCheckAll: false,
    };
    dropdownSettingsMultiple = {
        text: 'Select',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        singleSelection: false,
        enableCheckAll: true,
    };
    modalOption: NgbModalOptions = {};
    today = new Date();
    pageSize = 20;
    userId = '';
    genderArray = ['Male', 'Female'];
    srNumberArray = [];
    canCallStatus: Boolean = false;
    complainSourceArray = [];
    appointmentSourceData = []
    apptType = ['OPD', 'IPD'];
    apptTypeNew = [];
    allUserArray = [];
    genderArrayNew = [];
    categoryNameArray = [];
    categoryArrayNew = [];
    cxArrayNew = [];
    allTempCategoryArray = {};
    dropdownSettingsNew0 = {
        singleSelection: true,
        idField: 'DocObjectId',
        textField: 'firstName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
        closeDropDownOnSelection: true,
    };
    dropdownSettingsNew1 = {
        singleSelection: true,
        idField: 'id',
        textField: 'itemName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
        closeDropDownOnSelection: true,
    };
    dropdownSettingsNew2 = {
        singleSelection: false,
        idField: 'id',
        textField: 'itemName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };
    dropdownSettingsNew3 = {
        singleSelection: true,
        idField: 'hospitalId',
        textField: 'hospitalName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
        closeDropDownOnSelection: true,
    };
    cityArray = [];
    allOpdTypeArray = [];
    issueCat = [];
    issueCatNew = [];
    statusArr = [];
    statusArrNew = [];
    teamArr = [];
    teamArrNew = [];
    clinicsHospitalsList = [];
    patientEscalationCategory:[];
    patientEscalationIssue:[];
    patientEscalationHospitalIssueType:[];
    patientEscalationDischargeIssueType:[];
    public wsMessages: Subject<any>;
    wsMessagesObservable: Subject<any> = new Subject();
    notiDate = 'latest';
    notificationCount = 0;
    showNotiSpinner = false;
    notificationOptionData = [];

    constructor(
        private http: HttpServiceService,
        private constants: ConstantsService,
        private sanitizer: DomSanitizer,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private datePipe: DatePipe,
        public router: Router,
        public dialog : MatDialog,
        private wsService: WebSocketService,

    ) {
        this.modalOption.backdrop = 'static';
        // this.modalOption.keyboard = false;
    }

    removeDuplicatesArray(array, key) {
        return _.uniqBy(array, key);
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    doctorQuery() {
        if(this.doctorArrayNew && this.doctorArrayNew.length > 0 && !this.getAllData) {

        } else {
            this.doctorArray = [];
            this.doctorArrayNew = [];
            this.http
                .postData(this.constants.getCXDashDataQuery, {
                    className: 'Doctors',
                    projectObj: { firstName: 1, DocObjectId: 1, objectId: '$_id' },
                    obj: [{ _created_at: { $exists: true },isActive:true }],
                })
                .then((res) => {
                    this.doctorArray = res['data'];
                    // console.log(this.doctorArray)
                    this.doctorArrayNew = this.makeDropdownObjArray(
                        this.doctorArray,
                        'firstName'
                    );
                    this.doctorArrayNew = this.doctorArrayNew.filter(
                        (x) => x.itemName
                    );
                });
        }
        
    }

    feedbackCall(data) {
        return this.http.postData(this.constants.appointment, data);
    }

    complaintCall(data) {
        return this.http.postData(this.constants.ceDashboardComplaint, data);
    }

    dieticianCall(data) {
        return this.http.postData(this.constants.ceDashboardDietician, data);
    }

    appointmentProcess(result) {
        const slotObject = {
            fromDate: result.bookAppointmentSlot.substr(
                0,
                result.bookAppointmentSlot.indexOf('-') - 1
            ),
            toDate: result.bookAppointmentSlot.substr(
                result.bookAppointmentSlot.indexOf('-') + 1,
                result.bookAppointmentSlot.length
            ),
        };
        const isoTimeObject = this.getDateformat(
            result.bookAppointmentDate,
            slotObject
        );
        const reqObject = {
            leadId: result.leadId,
            ActiveOwner: 'Doc',
            appointmentStatus: 'Active',
            AppointmentDate: result.bookAppointmentDate,
            AppointmentStartTime: {
                __type: 'Date',
                iso: isoTimeObject.startTime.toISOString(),
            },
            AppointmentEndTime: {
                __type: 'Date',
                iso: isoTimeObject.endTime.toISOString(),
            },
            Hospital_clinic: {
                'Hospital City': result.bookAppointmentHospital.city,
                'Hospital Name': result.bookAppointmentHospital.hospitalName,
                id: result.bookAppointmentHospital.objectId,
            },
            SurgeryType: result.SurgeryType,
            BDAssigned: result.BDAssigned,
            BDStatus: 'OPDScheduled',
            Disease: result.Disease,
            DocAssigned: result.doctorSelected,
            CashAmount: Number(result.bookAppointmentamountOPD),
            PaymentMode: result.bookAppointmentmode,
            opdType: result.bookAppointmentopdType,
            AppointmentType: result.bookAppointmentopd,
            TotalAmount: Number(result.bookAppointmentamountOPD),
        };

        return reqObject;
    }

    getDateformat(date, timing) {
        const fromDate = timing.fromDate;
        const toDate = timing.toDate;

        const startTimeTemp = date + ' ' + fromDate + ':00';
        const startTime = new Date(startTimeTemp);

        const endTimeTemp = date + ' ' + toDate + ':00';
        const endTime = new Date(endTimeTemp);

        return { startTime, endTime };
    }

    hospitalQuery() {
        if(this.hospitalArray && this.hospitalArray.length > 0 && !this.getAllData) {

        } else {
            this.hospitalArray = [];
            this.hospitalArrayNew = [];
            this.http
                .postData(this.constants.getCXDashDataQuery, {
                    className: 'Hospitals',
                    projectObj: { hospitalName: 1, objectId: '$_id', city: 1 },
                    obj: [{ isActive: true }],
                })
                .then((res) => {
                    this.hospitalArray = res['data'];
                    this.hospitalArrayNew = this.makeDropdownObjArray(
                        this.hospitalArray,
                        'hospitalName'
                    );
                });
            }
        
    }

    getCxListData() {
        this.allUserArray = [];
        this.cxArray = [];
        this.cxArrayNew = [];
        if(this.allUserArray && this.allUserArray.length > 0 && !this.getAllData) {
            // console.log(this.allUserArray);
            // console.log(this.cxArray);
            // console.log(this.cxArrayNew);
        } else {
            this.allUserArray = [];
            this.cxArray = [];
            this.cxArrayNew = [];
            this.http
            .postData(this.constants.getCXDashDataQuery, {
                className: 'User',
                projectObj: {
                    objectId: '$_id',
                    username: 1,
                    role: 1,
                    otherRoles: 1,
                },
                obj: [{ isActive: true }],
            })
            .then((res) => {
                this.allUserArray = res['data'];
                res['data'].forEach((single, index) => {
                    if (
                        single.otherRoles &&
                        Array.isArray(single.otherRoles) &&
                        single.otherRoles.length > 0 &&
                        (single.otherRoles.includes('ceDash') ||
                            single.otherRoles.includes('ceDashAdmin') ||
                            single.otherRoles.includes('ceDashUser'))
                    ) {
                        this.cxArray.push(single);
                        this.cxArrayNew.push({
                            id: single['objectId'],
                            itemName: single['username'],
                        });
                    }
                });
                this.cxArray = this.removeDuplicatesArray(
                    this.cxArray,
                    'objectId'
                );
                this.cxArrayNew = this.removeDuplicatesArray(
                    this.cxArrayNew,
                    'id'
                );
            });
        }
        
    }

    connectWebSocket(isReconnect: boolean = false) {
        const userId = localStorage.getItem('userId');
        if (userId) {
            this.wsMessages = <Subject<any>> (
                this.wsService
                    .connect(
                        `${environment.webSocketUrl}?user_id=${userId}&product=cxDashboard`,
                        isReconnect
                    )
                    .map((response: MessageEvent): any => {
                        const data = JSON.parse(response.data);
                        return data;
                    })
            );
            this.subscribeWsMessages();
        }
    }
    subscribeWsMessages() {
        this.wsMessages.subscribe(
            message => {
                this.wsMessagesObservable.next(message);
            },
            error => {
                this.connectWebSocket(true);
            },
            () => {
                console.log('socket connection closed');
                console.log('Reconnecting...');
                this.connectWebSocket(true);
            }
        );
    }
    fetchNotificationRelated(markRead = false) {
        this.fetchNotificationCount(markRead);
    }
    fetchNotificationCount(markRead) {
        this.fetchNotificationContent(this.notiDate, markRead);
        this.http
            .getData(
                this.constants.notificationCount.replace(
                    'userId',
                    localStorage.getItem('userId')
                ) + '?app=cxDashboard'
            )
            .then(res => {
                if (res['status'] === 'Success') {
                    this.notificationCount = res['result'];
                    console.log(this.notificationCount)
                }
            });
    }
    fetchNotificationContent(type, markRead = false) {
        this.showNotiSpinner = true;
        this.http
            .getData(
                JSON.parse(
                    JSON.stringify(
                        this.constants.notificationData.replace(
                            'userId',
                            localStorage.getItem('userId')
                        )
                    )
                ) +
                '?app=cxDashboard&type=' +
                type +
                '&date=' +
                this.formatDate(this.addDays(this.today, 0))
            )
            .then(res => {
                this.showNotiSpinner = false;
                if (res["status"] === "Success") {
                    let tempArray = [];
                    res["result"].forEach(x => {
                        if (x.type !== 'refreshPickedByNotification') {
                            tempArray.push({
                                ...x,
                                ...x["data"]
                            });
                        }
                    });
                    this.notificationOptionData = JSON.parse(
                        JSON.stringify(tempArray)
                    );
                    console.log(this.notificationOptionData)
                    return;
                }
            });
    }
    markReadOrClickNotiFications(data) {
        if (data && Array.isArray(data) && data.length > 0) {
            this.http
                .postData(this.constants.updateNotificationData, { data: data })
                .then(res => {
                    this.fetchNotificationRelated();
                });
        }
    }

    getCategoryDiseaseMapping() {
        if(this.categoryArrayNew && this.categoryArrayNew.length > 0 && !this.getAllData) {

        } else {
            this.categoryNameArray = [];
            this.categoryArrayNew = [];
            this.allTempCategoryArray = [];
            this.allOpdTypeArray = [];
            this.http
                .postData(this.constants.getCXDashDataQuery, {
                    className: 'DiseaseSurgeryList',
                    obj: [{ _created_at: { $exists: true } }],
                })
                .then((res) => {
                    this.allOpdTypeArray = ["WalkIn - Follow Up","WalkIn - new","Online Consult", "Earwax Removal", "Ear/Nasal Packing"];
                    res['data'].forEach((single, index) => {
                        if (
                            single.newDuration &&
                            typeof single.newDuration === 'object' &&
                            Object.keys(single.newDuration).length > 0
                        ) {
                            this.allOpdTypeArray = [
                                ...this.allOpdTypeArray,
                                ...Object.keys(single.newDuration),
                            ];
                        }
                        this.allTempCategoryArray[single['Disease']] = single;
                        this.categoryNameArray.push(single['Disease']);
                        this.categoryArrayNew.push({
                            id: index,
                            itemName: single['Disease'],
                        });
                    });
                    const tempOpdTypeArr = this.makeDropdownArray(
                        this.removeArrayDuplicates(this.allOpdTypeArray)
                    );
                    this.allOpdTypeArray = JSON.parse(
                        JSON.stringify(tempOpdTypeArr)
                    );
                });
        }
    }

    getCity() {
        if(this.cityArray && this.cityArray.length > 0 && !this.getAllData) {

        } else {
            this.http
            .postData(this.constants.getCXDashDataQuery, {
                className: 'City_Region_Map',
                projectObj: { objectId: '$_id', CityName: 1 },
                obj: [{ _created_at: { $exists: true } }],
            })
            .then((res) => {
                res['data'].forEach((single) => {
                    this.cityArray.push(single['CityName']);
                });
                this.cityArray = this.removeArrayDuplicates(
                    this.cityArray.sort().filter(Boolean)
                );
            });
        }
        
    }

    commonNetworkCall() {
        
        let newDate = localStorage.getItem("date");
        let date = new Date().getDate();
        // console.log(newDate);
        // console.log('tannuj', parseInt(newDate));
        if(newDate) {
            // this.getAllData = false;
            if(date - parseInt(newDate) != 0) {
                this.getAllData = true;  
                localStorage.setItem("date", JSON.stringify(date)) 
            } else {
                this.getAllData = false; 
            }
        } else {
            this.getAllData = true;
            localStorage.setItem("date", JSON.stringify(date)) 
        }
        
        Promise.all([
            this.getCxListData(),
            this.getCommonData(),
            this.doctorQuery(),
            this.hospitalQuery(),
            this.getComplainSource(),
            this.getCategoryDiseaseMapping(),
            this.getCity(),
            this.getCommonNew(),
            this.patientEscalations()
        ]);

        this.isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
                this.userId = localStorage.getItem('userId');
                this.genderArrayNew = this.makeDropdownArray(this.genderArray);
                this.apptTypeNew = this.makeDropdownArray(this.apptType);

    }

    getCommonNew() {
        if(this.teamArr && this.teamArr.length > 0 && !this.getAllData) {

        } else {
            this.http.getDataAuth(this.constants.commonDoctorComplaintData + '?doctorId=').then(res => {
                this.issueCat = res['result']['issueCategory'];
                this.issueCatNew = this.makeDropdownObjArray(this.issueCat,'issueCategory');
                this.statusArr = res['result']['status'];
                this.statusArrNew = this.makeDropdownArray(this.statusArr);
                this.teamArr = this.issueCat.map(x => x.concernedTeam);
                this.teamArrNew = this.makeDropdownArray(this.teamArr);
    
                const uniqueChars = [];
                this.teamArr.forEach((c) => {
                    if (!uniqueChars.includes(c)) {
                        uniqueChars.push(c);
                    }
                });
                this.teamArr = uniqueChars.sort();
            });
        }
        
    }

    getDoc(id) {
        this.http.getDataAuth(this.constants.commonDoctorComplaintData + '?doctorId=' + id).then(res => {
            this.issueCat = res['result']['issueCategory'];
            this.issueCatNew = this.makeDropdownObjArray(this.issueCat, 'issueCategory');
            this.clinicsHospitalsList = res['result']['clinicsHospitalsList'];
            // console.log(this.clinicsHospitalsList)

        });
    }

    makeDropdownArray(array) {
        if (array && Array.isArray(array) && array.length > 0) {
            let id = 1;
            const tempArray = [];
            array.forEach((x) => {
                const obj = {};
                obj['id'] = id;
                obj['itemName'] = x;
                tempArray.push(obj);
                id++;
            });
            return _.orderBy(tempArray, 'itemName');
        } else {
            return [];
        }
    }

    makeDropdownObjArray(array, key) {
        let id = 1;
        const tempArray = [];
        array.forEach((x) => {
            const obj = {};
            obj['id'] = id;
            obj['itemName'] = x[key];
            tempArray.push(obj);
            id++;
        });
        return _.orderBy(tempArray, 'itemName');
    }

    makeDropdownObjArrayWithId(array, key, keyId) {
        if (!array || array.length < 1) {
            return [];
        }
        let id = 1;
        const tempArray = [];
        array.forEach((x) => {
            const obj = {};
            obj['id'] = x[keyId];
            obj['itemName'] = x[key];
            tempArray.push(obj);
            id++;
        });
        return _.orderBy(tempArray, 'itemName');
    }

    getDataFromParse() {
        if (this.doctorArray && this.hospitalArray && this.questionsArray) {
            return {
                doctorArray: this.doctorArray,
                hospitalArray: this.hospitalArray,
                questionsArray: this.questionsArray,
                cxArray: this.cxArray,
            };
        }
    }

    sortArrayOfObjects = (array, key) => {
        if(!array){
            return [];
        }
        if(!array.length){
            return [];
        }
        return array.sort(function (a, b) {
            const keyA = a[key];
            const keyB = b[key];
            if (keyA < keyB) { return -1; }
            if (keyA > keyB) { return 1; }
            return 0;
        });
    }

    getCommonData() {
        if(this.questionsArray && this.questionsArray.length > 0 && !this.getAllData) {

        } else {
            this.http
            .postData(this.constants.getCXDashDataQuery, {
                className: 'CommonData',
                projectObj: { Name: 1, Data: 1 },
                obj: [
                    {
                        Name: {
                            $in: ['CXDashboard', 'CXDashboardComplainSource', 'appointmentSourceData'],
                        },
                    },
                ],
            })
            .then((res) => {
                this.questionsArray = res['data'].find(
                    (x) => x['Name'] === 'CXDashboard'
                )['Data'][0]['value'];
                this.complainSourceArray = res['data'].find(
                    (x) => x['Name'] === 'CXDashboardComplainSource'
                )['Data'];
                this.appointmentSourceData = res['data'].find(
                    (x) => x['Name'] === 'appointmentSourceData'
                )['Data'];
            });
        }
        
    }

    getComplainSource() {
        return true;
    }

    viewPatientJourney(customer) {
        const modalRef = this.modalService.open(PatientJourneyComponent, {
            windowClass: 'my-class',
        });
        let url;
        if (customer && customer['leadId']) {
            url = localStorage.getItem('pjUrl') + 'user/' + customer['leadId'];
        } else {
            url = localStorage.getItem('pjUrl');
        }
        const tempUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        modalRef.componentInstance.data = tempUrl;
    }

    returnMtdDate() {
        let tempObj = {
            start_date: this.today.toISOString().substring(0, 8).concat("01"),
            end_date: this.today.toISOString(),
        };
        let date = new Date(tempObj["end_date"]);
        tempObj["end_date"] = this.formatDate(this.addDays(date, 0));
        tempObj["end_date"] += "T18:30:00.000Z";
        let tempStartDate = this.removeDays(new Date(tempObj["start_date"]), 1);
        tempObj["start_date"] =
            tempStartDate.toISOString().split("T")[0] + "T18:30:00.000Z";
        return tempObj;
    }

    removeDays(myDate, days) {
        return new Date(myDate.getTime() - days * 24 * 60 * 60 * 1000);
    }

    formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) { month = '0' + month; }
        if (day.length < 2) { day = '0' + day; }

        return [year, month, day].join('-');
    }

    addDays(myDate, days) {
        return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
    }

    callCustomer(customer) {
        if (this.canCallStatus) {
            if (this.srNumberArray.length < 1) {
                this.toastr.error('No User SR Number');
                return false;
            }
            const mobileArray: any = [];
            if (customer['leadMobile']) {
                mobileArray.push({
                    field: 'Mobile',
                    data: customer['leadMobile'],
                    index: 0,
                });
            }
            if (
                customer['patient_other_number'] &&
                customer['patient_other_number'].length > 0
            ) {
                customer['patient_other_number'] = this.removeArrayDuplicates(
                    customer['patient_other_number']
                );
                customer['patient_other_number'].forEach((x, i) => {
                    if (customer['leadMobile'] !== x) {
                        mobileArray.push({
                            field: 'otherNumber',
                            data: x,
                            index: i,
                        });
                    }
                });
            }
            if (
                customer['patient_wa_number'] &&
                customer['patient_wa_number'].length > 0
            ) {
                customer['patient_wa_number'].forEach((x, i) => {
                    const index = mobileArray.findIndex((y) => y['data'] === x);
                    if (index === -1) {
                        mobileArray.push({
                            field: 'waNumber',
                            data: x,
                            index: i,
                        });
                    }
                });
            }
            const dialogRef = this.dialog.open(CallDialogComponent, {
                width: "25em",
                panelClass : "call-modal",
                data:{
                    data : customer,
                    category:customer.Disease,
                    mobile: mobileArray,
                    srNumber: this.srNumberArray,
                }
            });

            dialogRef.afterClosed().subscribe(res=>{


            })
            // const modalRef = this.modalService.open(ClickToCallComponent, {
            //     windowClass: 'clickToCall',
            //     ...this.modalOption,
            // });
            // modalRef.componentInstance.data = {
            //     mobile: mobileArray,
            //     srNumber: this.srNumberArray,
            //     category: customer['disease'],
            // };
            // modalRef.result.then((res) => {
            //     if (res && res['srNumber']) {
            //         const callBody = {
            //             leadId: customer['leadId'],
            //             actor_id: this.userId,
            //             k_number: res['srNumber'],
            //             LeadSource: 'CXDashboard',
            //         };
            //         if (res['cNumber']['field'] !== 'Mobile') {
            //             callBody['field'] = res['cNumber']['field'];
            //             callBody['index'] = res['cNumber']['index'];
            //         }
            //         this.http
            //             .postData(
            //                 this.constants.forwardingApi,
            //                 this.getForwardApiName(
            //                     'parse',
            //                     'ClickTOCall',
            //                     callBody
            //                 )
            //             )
            //             .then((res) => {
            //                 this.toastr.success('Call will be connected Soon');
            //             })
            //             .catch((err) => {
            //                 this.toastr.error(err);
            //             });
            //     }
            // });
        }
    }


    removeArrayDuplicates(array) {
        return array.filter((item, pos) => {
            return array.indexOf(item) === pos;
        });
    }

    removeLocalStorageItems() {
        localStorage.removeItem('reqObjToHit');
    }

    returnSafeUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    async getOtherDetails(appId) {
        const res = await this.http.postData(this.constants.getPatientDetails, {
            appId: appId,
        });
        if (res && res['status'] && res['status'] === 200) {
            return res['data'];
        } else {
            return [];
        }
    }

    removeQuotes(str) {
        if (str && str.includes(':')) {
            return this.datePipe.transform(
                str.replace(/"/g, ''),
                'd MMM, hh:mm a'
            );
        } else if (str) {
            return str.replace(/"/g, '');
        } else {
            return str;
        }
    }

    returnKeyBasedOfSubtask(array, subtask, value, key) {
        if (array && array.length > 0) {
            let finalValue;
            array.map((y) => {
                if (y['subtask'] && y['subtask'] === subtask) {
                    if (y['data'] && y['data'].length > 0) {
                        y['data'].map((z) => {
                            if (z[value]) {
                                finalValue = z[value][key];
                            }
                        });
                    }
                }
            });
            return finalValue;
        }
    }

    async fetchLatestUserValues() {
        if (!localStorage.getItem('isCXLogged99')) {
            return false;
        }
        const res = await this.http.getData(
            this.constants.getLatestUserData +
                '/' +
                localStorage.getItem('userId')
        );
        if (res && res['status'] === 200 && res['data']) {
            this.updateUserData(res['data'], false);
        }
        return true;
    }

    updateUserData(userData, isRoute = false) {
        const role = userData['role'];
        let otherRoles;
        otherRoles = userData['otherRoles'];
        if (
            role.match(/ceDash/gi) ||
            (otherRoles.length > 0 &&
                (otherRoles.includes('ceDashUser') ||
                    otherRoles.includes('ceDashAdmin')))
        ) {
            localStorage.setItem('isCXLogged99', 'true');
            const userEmail = userData['email'];
            let userName = userData['username'];
            const number = userData['Mobile'];
            if (number && number.length > 0) {
                localStorage.setItem('Mobile', number[0]);
            }
            if (userData['canTakeAction']) {
                this.canCallStatus = true;
            } else {
                this.canCallStatus = true;
            }
            localStorage.setItem('userEmail', userEmail);
            if (userName.includes('.')) {
                userName = userName.split('.').join('');
            }
            localStorage.setItem('userName', userName);
            localStorage.removeItem('isAdmin');
            if (
                role.match(/ceDashAdmin/gi) ||
                (otherRoles.length > 0 && otherRoles.includes('ceDashAdmin'))
            ) {
                localStorage.setItem('isAdmin', 'true');
            }
            const srNumber = userData['SRNumber'];
            if (srNumber && Array.isArray(srNumber) && srNumber.length > 0) {
                localStorage.setItem('srNumber', userData['SRNumber']);
                localStorage.setItem('SRNumber', userData['SRNumber']);
            }
            this.isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
            this.userId = localStorage.getItem('userId');
            this.srNumberArray = userData['SRNumber'];
            this.genderArrayNew = this.makeDropdownArray(this.genderArray);
            this.apptTypeNew = this.makeDropdownArray(this.apptType);
            if (isRoute) {
                this.router.navigate(['/opdFeedback']);
            }
            this.commonNetworkCall();
        }
    }

    getForwardApiName(type, endPoint, data) {
        const obj = {
            type: type,
            endPoint: endPoint,
            data: data,
        };
        return obj;
    }

    makeDateFormat(value) {
        return this.datePipe.transform(value, 'yyyy-MM-dd');
    }

    async getDoctorbasedOnDisease() {
        const data = {
            category: [],
            isActive: [true],
            role: ['Doc'],
        };
        const res = await this.http.postData(
            this.constants.getDoctorBasedOnCategory,
            data
        );
        return res['data'];
    }

    getUserNameBasedOnID(id) {
        let name = '';
        if (id) {
            if (
                this.allUserArray &&
                Array.isArray(this.allUserArray) &&
                this.allUserArray.length > 0
            ) {
                const userObj = this.allUserArray.find((x) => x['_id'] === id);
                if (userObj && userObj['username']) {
                    name = userObj['username'];
                }
            }
        }
        return name;
    }

    async getSignedUrl(urls) {
        let signedUrl = [];
        const res = await this.http.postData(this.constants.signedUrl, {
            urls: urls,
        });
        if (res && res['status'] === 'Success') {
            signedUrl = res['result'];
        }
        return signedUrl;
    }

    patientEscalations() {
        if(this.patientEscalationCategory && this.patientEscalationCategory.length >0 && !this.getAllData) {

        } else {
            this.doctorArray = [];
            this.doctorArrayNew = [];
            this.http
            .postData(this.constants.getCXDashDataQuery, {
                "className": "CommonData",
                "projectObj": {
                    "_id": 0,
                    "Data": 1,
                    "Name": 1
                },
                "obj": [
                    {
                        "Name": {
                            "$in": [
                                "PatientEscalationCategory",
                                "PatientEscalationIssue",
                                "PatientEscalationHospitalIssueType",
                                "PatientEscalationDischargeIssueType"
                            ]
                        }
                    }
                ]
            })
            .then((res) => {
                this.patientEscalationCategory=res['data'][this.findItemIndexInArray(
                                                    res['data'],
                                                    "Name",
                                                    "PatientEscalationCategory"
                                                )]['Data'];
                this.patientEscalationIssue=res['data'][this.findItemIndexInArray(
                                                res['data'],
                                                "Name",
                                                "PatientEscalationIssue"
                                            )]['Data'];
                this.patientEscalationHospitalIssueType=res['data'][this.findItemIndexInArray(
                                                    res['data'],
                                                    "Name",
                                                    "PatientEscalationHospitalIssueType"
                                                )]['Data'];
                this.patientEscalationDischargeIssueType=res['data'][this.findItemIndexInArray(
                                                    res['data'],
                                                    "Name",
                                                    "PatientEscalationDischargeIssueType"
                                                )]['Data'];
                console.log(res)
                console.log(res['data'])
                console.log(this.patientEscalationCategory)
            });
        }
        
    }
    findItemIndexInArray(array, key, value) {
        return array.findIndex((y) => y[key] === value);
    }
}
