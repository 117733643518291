import { Component, OnDestroy, OnInit } from "@angular/core";
import { environment } from "../environments/environment";
import { fromEvent, Observable, Subscription } from "rxjs";
import { CommonService } from "./shared/services/common.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    onlineEvent: Observable<Event>;
    offlineEvent: Observable<Event>;

    subscriptions: Subscription[] = [];

    connectionStatusMessage: string;
    connectionStatus: string;
    constructor(private common:CommonService) {
        this.common.fetchLatestUserValues()
    }

    ngOnInit(): void {
        /**
         * Get the online/offline status from browser window
         */
        this.onlineEvent = fromEvent(window, "online");
        this.offlineEvent = fromEvent(window, "offline");

        this.subscriptions.push(
            this.onlineEvent.subscribe((e) => {
                this.connectionStatusMessage = "Back to online";
                this.connectionStatus = "online";
                console.log("Online...");
            })
        );

        this.subscriptions.push(
            this.offlineEvent.subscribe((e) => {
                this.connectionStatusMessage =
                    "Connection lost! You are not connected to internet";
                this.connectionStatus = "offline";
                console.log("Offline...");
            })
        );
    }

    ngOnDestroy(): void {
        /**
         * Unsubscribe all subscriptions to avoid memory leak
         */
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }
}

