import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { HttpServiceService } from 'src/app/shared/services/http-service.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {
  otp:string=''
  unMaskRemark:string=''
  isApiCallInProgress:boolean = false
  reqobj:any
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private http:HttpServiceService,
    private url:ConstantsService,
    private snackBar:SnackBarService
  ) { }

  ngOnInit() {
    this.reqobj = {
      type:'parse',
      endPoint:'sendLeadUnmaskOtp',
      data:{
        leadId:this.data.leadId,
        userId:localStorage.getItem('userId'),
        userName:localStorage.getItem('userName')
      }
    }
    this.requestandVerficationOtpCall(this.url.forwarding,this.reqobj)
    .then((res:any)=>{
      if (res.status == 200 && res.data.result.status == 1) {
        this.snackBar.success('OTP sent.')
      }else{
        this.snackBar.info('Unable To send OTP.')
      }
    })
    .catch(()=>{
      this.snackBar.error('Unable To Send OTP')
    })
    .finally(()=>{
      this.isApiCallInProgress=false
    })
  }

  closeModal(data?){
    this.dialogRef.close(data)
  }
  otpInputHandler(e){
    if (e && typeof(e)==='string'){
      if (e && e.length>1) return this.otp = this.otp.slice(0,-1)+e[e.length-1]
      return this.otp = this.otp+e
    } 
    return this.otp = this.otp.slice(0,-1)
  }

  submitOtp(){
    this.unMaskRemark = this.unMaskRemark.trim()
    if (!this.unMaskRemark) {
      this.snackBar.info('Please Enter Remark')
      return;
    }
    this.reqobj = {
      ...this.reqobj,
      endPoint:'verifyLeadUnmaskOtp',
      data:{
        ...this.reqobj.data,
        otp:this.otp,
        otpReason: this.unMaskRemark
      }
    }
    this.requestandVerficationOtpCall(this.url.forwarding,this.reqobj)
    .then((res:any)=>{
      if (res.status == 200 && res.data.result.status == 1) {
        if (res.data.result.message){
          this.snackBar.success(res.data.result.message)
        }else{
          this.snackBar.success('OTP Verify.')
        }
        if (res.data.result.mobile && !Array.isArray(res.data.result.mobile)) {
          this.closeModal([res.data.result.mobile])
        }else{
          this.closeModal(res.data.result.mobile)
        }
      }else{
        if (res.data.result.message){
          this.snackBar.warning(res.data.result.message)
        }else{
          this.snackBar.warning('Unable To Verify OTP!')
        }
      }
    })
    .catch(err=>{
      this.snackBar.error('Unable To Verify OTP!')
    })
    .finally(()=>{
      this.isApiCallInProgress=false
    })
  }

  requestandVerficationOtpCall(url:string,reqObj:any){
    this.isApiCallInProgress=true
    return this.http.postData(url,reqObj)
  }
}
