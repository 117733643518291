import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ConstantsService } from "./constants.service";
import { HttpServiceService } from "./http-service.service";
import { ToastrService } from "ngx-toastr";
import {
    DomSanitizer,
    SafeResourceUrl,
    SafeUrl,
} from "@angular/platform-browser";
import {
    NgbActiveModal,
    NgbModal,
    NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";

@Injectable()
export class UtilService {
    modalOption: NgbModalOptions = {};
    today = new Date();
    SRNumberArray: any = [];
    constructor(
        private http: HttpServiceService,
        private constants: ConstantsService,
        private toastr: ToastrService,
        private sanitizer: DomSanitizer,
        private modalService: NgbModal,
        private datePipe: DatePipe
    ) {
        this.modalOption.backdrop = "static";
        this.modalOption.keyboard = false;
        this.SRNumberArray = JSON.parse(localStorage.getItem("SRNumberArray"));
    }

    async downloadFile(fileURL, fileName) {
        if (fileURL) {
            var save = document.createElement("a");
            let res = await this.getSignedUrl([fileURL])
            if(res && Array.isArray(res) && res.length > 0){
                fileURL = res[0]
            }
            save.href = fileURL;
            save.target = "_blank";
            save.download = fileName || "unknown";

            var evt = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: false,
            });
            save.dispatchEvent(evt);

            (window.URL || window["webkitURL"]).revokeObjectURL(save.href);
        }
    }

    returnDateFormatPipe(value) {
        if (value) {
            return this.datePipe.transform(value, "d MMM yy, h:mm a");
        } else {
            return "NA";
        }
    }

    returnOnlyDateFormatPipe(value) {
        if (value) {
            return this.datePipe.transform(value, "d MMM yy");
        } else {
            return "NA";
        }
    }

    checkValueExists(value) {
        if (value) {
            return value;
        } else {
            return "NA";
        }
    }

    sortArrayOfObjectByDate(array, key) {
        return array
            .filter(Boolean)
            .sort(
                (a, b) =>
                    new Date(b[key]).valueOf() - new Date(a[key]).valueOf()
            );
    }

    convertToIst(parseTimeObject) {
        let dateString = parseTimeObject;
        let dateObject = new Date(dateString);
        let localTime = dateObject.getTime();
        let localOffSet = dateObject.getTimezoneOffset() * 60000;
        let utc = localTime + localOffSet;
        let offSet = 5.5;
        let indiaDateMS = utc + 3600000 * offSet;
        dateObject = new Date(indiaDateMS);
        let finalDateInIso = dateObject.toJSON();
        return finalDateInIso;
    }

    removeArrayDuplicates(array) {
        return array.filter(function (item, pos) {
            return array.indexOf(item) == pos;
        });
    }

    async getSignedUrl(urls){
        let signedUrl = []
        let res = await this.http.postData(this.constants.signedUrl, {urls:urls})
        console.log(res)
        if(res && res['status'] === 'Success'){
            signedUrl = res['result'] 
        }
        return signedUrl
    }
}
