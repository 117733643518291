import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ConstantsService {

  route= environment['parseUrl']+'/functions/';
  routeNew = environment["routeUrl"] + "api/";
  docServiceUrl = environment['docServiceUrl']
  v1 = environment['docServiceUrlv1']+'/api/v1';
  bdDashboardRoute = this.docServiceUrl + '/bd-dashboard/'
  cxDashDocRoute = this.docServiceUrl + '/cx-dashboard/'
  getRemarks = this.docServiceUrl + '/getRemarks';
  appointment = this.cxDashDocRoute+'ceDashboard';

  commonDoctorComplaintData = this.v1+'/commonDoctorComplaintData'
  doctorComplaints = this.v1+'/doctorComplaints';
  cxRemarks = this.doctorComplaints+'/cxRemarks';

  notificationCount = this.routeNew + "notification/userId/count";
  notificationData = this.routeNew + "notification/userId";
  updateNotificationData = this.routeNew + "notification";
  
  forwardingApi = this.bdDashboardRoute + 'forwarding'
  ceDashboardComplaint = this.cxDashDocRoute + 'ceDashboardComplaint';
  ceDashboardDietician = this.cxDashDocRoute + 'ceDashboardDietician'
  ceDashboardPatientSearch = this.cxDashDocRoute + 'ceDashboardPatientSearch';
  getPatientDetails = this.cxDashDocRoute + 'getPatientDetails'
  getCXDashDataQuery = this.cxDashDocRoute + 'getCXDashDataQuery'
  updateCXDashDataQuery = this.cxDashDocRoute + 'updateCXDashDataQuery';
  addCXDashDataQuery = this.cxDashDocRoute + 'addCXDashDataQuery'
  getLatestUserData = this.docServiceUrl + '/getUser'
  authAPI = this.docServiceUrl + '/auth/'
  requestOtp = this.authAPI + 'request-otp'
  submitOtp = this.authAPI + 'submit-otp'
  refreshToken = this.authAPI + 'refresh-token'
  logout = this.authAPI + 'logout'
  saveImage = this.bdDashboardRoute + 'uploadFiles'
  getDoctorBasedOnCategory = this.bdDashboardRoute + 'getDoctorBasedOnCategory'
  getData = this.bdDashboardRoute+'getBDDashDoctorData';
  patientEscalation=this.cxDashDocRoute+"patientEscalation"

  signedUrl = this.docServiceUrl + '/EMR/getSignedUrl'
  srNumber = this.docServiceUrl + '/bd-dashboard/getSRNumber'
  forwarding = this.docServiceUrl + '/bd-dashboard/forwarding'
  clickToCall = this.docServiceUrl + '/bd-dashboard/ClickTOCall'
  constructor() {
  }

}
